import { Message } from '..'
import { AiOutlineApartment, AiOutlineTeam, AiOutlineUserAdd, AiOutlineAppstoreAdd, AiOutlineEdit, AiOutlineUpload, AiOutlineFileDone, AiOutlineGlobal, AiOutlineFileExcel } from "react-icons/ai"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle, FaEye, FaQuestion } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'
import React, { useState } from "react"
import PhoneInput from 'react-phone-number-input'
import useBranchesHook from '../../api/branches'
import useUsersHook from '../../api/users'
import useUploadHook from '../../api/upload'
import moment from 'moment'

import * as Yup from "yup"
import "yup-phone"
import * as XLSX from 'xlsx'
import * as FileSaver from "file-saver"

import {
  inputCheckBox,
  inputText,
} from '../../utils/dynamicForm'


var FormBranches = ({
  id,
  edit,
  users,
  members,
  formCleanHandler,
  register,
  isError,
  errors,
  watch,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  isSuperAdmin,
  latitudeBranch,
  longitudeBranch,
  radiusBranch,
}) => {

  const { updateBranch } = useBranchesHook({ page: 1, q: '' })
  const { isLoading: isLoadingBranch, error: errorUpdate, mutateAsync: mutateAsyncUpdate } = updateBranch
  const { postUserByInput } = useUsersHook({ limit: 100000, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput
  const { postUpload } = useUploadHook()
  var { isLoading: isLoadingUpload, error: errorUpload, mutateAsync: mutateAsyncUpload } = postUpload

  var urlComplete = `${process.env.REACT_APP_URL_DOMAIN}`

  const headers = [
    { title: 'Nombre del Abonado Principal', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Estado', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Identificación', prop: `identification`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Número Celular', prop: 'phone', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Email', prop: 'email', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Ingreso Contraseña', prop: 'accountValidationInitialPasswordToken', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Bloqueo General', prop: 'blocked', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]

  const headersUsers = [
    { title: 'Nombre del Usuario', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Estado', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Rol', prop: 'role', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Identificación', prop: `identification`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Número Celular', prop: 'phone', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Email', prop: 'email', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Ingreso Contraseña', prop: 'accountValidationInitialPasswordToken', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Bloqueo General', prop: 'blocked', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]

  // Inicio - User
  const [idUser, setIdUser] = useState('')
  const [typeIdentificationUser, setTypeIdentificationUser] = useState(undefined)
  const [identificationUser, setIdentificationUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [lastNameUser, setLastNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phoneNumberUser, setPhoneNumberUser] = useState()
  const [sexUser, setSexUser] = useState('')
  const [birthdayUser, setBirthdayUser] = useState('')
  const [editUser, setEditUser] = useState(false)
  const [inputSearchUser, setInputSearchUser] = useState('')
  const [isActiveUser, setIsActiveUser] = useState(false)
  const [roleUser, setRoleUser] = useState('')
  const [isDisabledUser, setIsDisabledUser] = useState(true)
  const [isDisabledUserEdit, setIsDisabledUserEdit] = useState(true)
  const [errorsListUser, setErrorsListUser] = useState('')


  const cleanUser = async () => {

    setInputSearchUser('')
    setIsDisabledUser(true)
    setIsDisabledUserEdit(true)
    setIdUser('')
    setTypeIdentificationUser('')
    setIdentificationUser('')
    setBirthdayUser('')
    setSexUser('')
    setNameUser('')
    setLastNameUser('')
    setEmailUser('')
    setPhoneNumberUser('')
    setIsActiveUser(false)
    setRoleUser('')
    setEditUser(false)
    setErrorsListUser('')
    document.getElementById('isActiveUser').removeAttribute('checked')

  }

  const addUser = async () => {

    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if (birthdayUser != '') {
      let dateArray = birthdayUser.split('-')
      formatDateBirthday = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }

    const branchUpdate = await mutateAsyncUpdate({
      _id: id,
      users: {
        _id: idUser,
        typeIdentification: typeIdentificationUser,
        identification: identificationUser,
        birthday: formatDateBirthday,
        sex: sexUser,
        name: nameUser,
        lastname: lastNameUser,
        email: emailUser,
        phone: phoneNumberUser,
        role: roleUser,
        active: isActiveUser
      }, type: 'update_branch_users', urlComplete: urlComplete
    })

    users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,
        accountValidationEmailToken: branchUpdate.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.users[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.users[i].active,
        role: branchUpdate.users[i].typeUser
      })
    }
    cleanUser()
    document.getElementById("close-user-branch").click()
  }

  const editUserFunction = (idUser) => {
    setEditUser(true)
    setIsDisabledUser(true)
    setIsDisabledUserEdit(false)
    var userFound = users.find((e) => e._id == idUser)

    setIdUser(userFound._id)
    setTypeIdentificationUser(userFound.typeIdentification)
    setIdentificationUser(userFound.identification)
    setBirthdayUser(userFound.birthday != undefined ? userFound.birthday.substring(0, 10) : '')
    setSexUser(userFound.sex)
    setNameUser(userFound.name)
    setLastNameUser(userFound.lastname)
    setEmailUser(userFound.email)
    setPhoneNumberUser(userFound.phone)
    setIsActiveUser(userFound.active)
    setRoleUser(userFound.role == 'Admin' ? 'ADMIN_BRANCH' : 'MONITOR_BRANCH')
    if (userFound.active)
      document.getElementById("isActiveUser").checked = true
    else
      document.getElementById("isActiveUser").checked = false
  }

  const schemaSearchUser = Yup.object().shape({
    search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
  })

  const deleteUserFunction = async (idUser) => {

    const branchUpdate = await mutateAsyncUpdate({
      _id: id,
      users: {
        _id: idUser,
      },
      type: 'delete_branch_users',
    })

    users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,
        accountValidationEmailToken: branchUpdate.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.users[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.users[i].active,
        role: branchUpdate.users[i].typeUser
      })
    }
  }

  const searchUser = async () => {

    if (inputSearchUser == '') {
      setErrorsListUser('Ingrese un valor de búsqueda')
      return false
    }

    const retorno = await mutateAsyncPostSearchUser({ input: inputSearchUser })
    var errorMessage = ''
    cleanUser()

    if (retorno._id == undefined) {
      errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
      setErrorsListUser(errorMessage)
      setEditUser(false)
      setIsDisabledUser(false)
      setIsDisabledUserEdit(false)
    } else {
      setEditUser(true)
      setIsDisabledUser(true)
      setIsDisabledUserEdit(false)
    }

    setIdUser(retorno && retorno._id != undefined ? retorno._id : '')
    setTypeIdentificationUser(retorno && retorno.typeIdentification != undefined ? retorno.typeIdentification : '')
    setIdentificationUser(retorno && retorno.identification != undefined ? retorno.identification : '')
    setBirthdayUser(retorno && retorno.birthday != undefined ? retorno.birthday.substring(0, 10) : '')
    setSexUser(retorno && retorno.sex != undefined ? retorno.sex : '')
    setNameUser(retorno && retorno.name != undefined ? retorno.name : '')
    setLastNameUser(retorno && retorno.lastname != undefined ? retorno.lastname : '')
    setEmailUser(retorno && retorno.email != undefined ? retorno.email : '')
    setPhoneNumberUser(retorno && retorno.phone != undefined ? retorno.phone : '')
    setIsActiveUser(retorno && retorno.active != undefined ? retorno.active : false)
  }

  const formValidationSearchUser = async () => {
    let validacion = undefined
    try {

      validacion = await schemaSearchUser.validate({ search: inputSearchUser })
    }
    catch (errors) {
      setErrorsListUser(errors.message)
    }

    if (validacion !== '') {
      searchUser()
    }
  }

  // Fin - User

  // Inicio - Member
  const [idMember, setIdMember] = useState('')
  const [typeIdentificationMember, setTypeIdentificationMember] = useState(undefined)
  const [identificationMember, setIdentificationMember] = useState('')
  const [nameMember, setNameMember] = useState('')
  const [lastNameMember, setLastNameMember] = useState('')
  const [emailMember, setEmailMember] = useState('')
  const [phoneNumberMember, setPhoneNumberMember] = useState()
  const [sexMember, setSexMember] = useState('')
  const [birthdayMember, setBirthdayMember] = useState('')
  const [editMember, setEditMember] = useState(false)
  const [inputSearchMember, setInputSearchMember] = useState('')
  const [isActiveMember, setIsActiveMember] = useState(true)
  const [isDisabledMember, setIsDisabledMember] = useState(true)
  const [isDisabledMemberEdit, setIsDisabledMemberEdit] = useState(true)
  const [errorsListMember, setErrorsListMember] = useState('')


  const cleanMember = async () => {

    setInputSearchMember('')
    setIsDisabledMember(true)
    setIsDisabledMemberEdit(true)
    setIdMember('')
    setTypeIdentificationMember('')
    setIdentificationMember('')
    setBirthdayMember('')
    setSexMember('')
    setNameMember('')
    setLastNameMember('')
    setEmailMember('')
    setPhoneNumberMember('')
    setIsActiveMember(false)
    setEditMember(false)
    setErrorsListMember('')
    document.getElementById('isActiveMember').removeAttribute('checked')

  }

  const addMember = async () => {

    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if (birthdayMember != '') {
      let dateArray = birthdayMember.split('-')
      formatDateBirthday = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }


    const branchUpdate = await mutateAsyncUpdate({
      _id: id,
      members: {
        _id: idMember,
        typeIdentification: typeIdentificationMember,
        identification: identificationMember,
        birthday: formatDateBirthday,
        sex: sexMember,
        name: nameMember,
        lastname: lastNameMember,
        email: emailMember,
        phone: phoneNumberMember,
        active: isActiveMember,
        role: 'MEMBER_BRANCH',
      }, type: 'update_branch_members'
    })

    members.splice(0)

    for (let i = 0; i < branchUpdate.members.length; i++) {
      members.push({
        _id: branchUpdate.members[i].user._id,
        typeIdentification: branchUpdate.members[i].user.typeIdentification,
        identification: branchUpdate.members[i].user.identification,
        birthday: branchUpdate.members[i].user.birthday,
        sex: branchUpdate.members[i].user.sex,
        name: branchUpdate.members[i].user.name,
        lastname: branchUpdate.members[i].user.lastname,
        email: branchUpdate.members[i].user.email,
        phone: branchUpdate.members[i].user.phone,
        accountValidationEmailToken: branchUpdate.members[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.members[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.members[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.members[i].active,
        network: branchUpdate.members[i].network._id,
        branch: id,
      })
    }
    cleanMember()
    document.getElementById("close-branch-member").click()
  }

  const editMemberFunction = (idMember) => {
    setEditMember(true)
    setIsDisabledMember(true)
    setIsDisabledMemberEdit(false)
    var memberFound = members.find((e) => e._id == idMember)

    setIdMember(memberFound._id)
    setTypeIdentificationMember(memberFound.typeIdentification)
    setIdentificationMember(memberFound.identification)
    setBirthdayMember(memberFound.birthday != undefined ? memberFound.birthday.substring(0, 10) : '')
    setSexMember(memberFound.sex)
    setNameMember(memberFound.name)
    setLastNameMember(memberFound.lastname)
    setEmailMember(memberFound.email)
    setPhoneNumberMember(memberFound.phone)
    setIsActiveMember(memberFound.active)
    if (memberFound.active)
      document.getElementById("isActiveMember").checked = true
    else
      document.getElementById("isActiveMember").checked = false
  }

  const schemaSearchMember = Yup.object().shape({
    search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
  })

  const deleteMemberFunction = async (idMember) => {

    const branchUpdate = await mutateAsyncUpdate({
      _id: id,
      members: {
        _id: idMember,
      },
      type: 'delete_branch_members',
    })


    members.splice(0)


    for (let i = 0; i < branchUpdate.members.length; i++) {
      members.push({
        _id: branchUpdate.members[i].user._id,
        typeIdentification: branchUpdate.members[i].user.typeIdentification,
        identification: branchUpdate.members[i].user.identification,
        birthday: branchUpdate.members[i].user.birthday,
        sex: branchUpdate.members[i].user.sex,
        name: branchUpdate.members[i].user.name,
        lastname: branchUpdate.members[i].user.lastname,
        email: branchUpdate.members[i].user.email,
        phone: branchUpdate.members[i].user.phone,
        accountValidationEmailToken: branchUpdate.members[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.members[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.members[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.members[i].active,
        network: branchUpdate.members[i].network._id,
        branch: id,
      })
    }
    document.getElementById("icon-tab-1") != undefined ? document.getElementById("icon-tab-1").click() : ''
  }

  const searchMember = async () => {

    if (inputSearchMember == '') {
      setErrorsListMember('Ingrese un valor de búsqueda')
      return false
    }

    const retorno = await mutateAsyncPostSearchUser({ input: inputSearchMember })
    var errorMessage = ''
    cleanMember()

    if (retorno._id == undefined) {
      errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
      setErrorsListMember(errorMessage)
      setEditMember(false)
      setIsDisabledMember(false)
      setIsDisabledMemberEdit(false)
    } else {
      setEditMember(true)
      setIsDisabledMember(true)
      setIsDisabledMemberEdit(false)
    }

    setIdMember(retorno && retorno._id != undefined ? retorno._id : '')
    setTypeIdentificationMember(retorno && retorno.typeIdentification != undefined ? retorno.typeIdentification : '')
    setIdentificationMember(retorno && retorno.identification != undefined ? retorno.identification : '')
    setBirthdayMember(retorno && retorno.birthday != undefined ? retorno.birthday.substring(0, 10) : '')
    setSexMember(retorno && retorno.sex != undefined ? retorno.sex : '')
    setNameMember(retorno && retorno.name != undefined ? retorno.name : '')
    setLastNameMember(retorno && retorno.lastname != undefined ? retorno.lastname : '')
    setEmailMember(retorno && retorno.email != undefined ? retorno.email : '')
    setPhoneNumberMember(retorno && retorno.phone != undefined ? retorno.phone : '')
    setIsActiveMember(retorno && retorno.active != undefined ? retorno.active : false)
  }

  const formValidationSearchMember = async () => {
    let validacion = undefined
    try {

      validacion = await schemaSearchMember.validate({ search: inputSearchMember })
    }
    catch (errors) {
      setErrorsListMember(errors.message)
    }

    if (validacion !== '') {
      searchMember()
    }
  }

  var handleFileChange = (e) => {

    if (e.target.files) {
      var reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      errorUpload = ''
      reader.onload = async () => {
        var formData = new FormData()
        formData.append('file', e.target.files[0])

        var uploadResponse = await mutateAsyncUpload({ type: 'upload-members', formData, id: id })

        var fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        var fileExtension = ".xlsx"

        var workSheet = XLSX.utils.json_to_sheet(uploadResponse.resultado_excel);
        XLSX.utils.sheet_add_aoa(workSheet, [["ABONADO", "MAIL", "RESPUESTA"]], { origin: "A1" })
        var workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] }
        var excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" })
        var data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, 'Respuesta - Proceso subida de abonados' + fileExtension)

        members.splice(0)

        if (uploadResponse != undefined) {

          for (let i = 0; i < uploadResponse.resultado_members.members.length; i++) {
            members.push({
              _id: uploadResponse.resultado_members.members[i].user._id,
              typeIdentification: uploadResponse.resultado_members.members[i].user.typeIdentification,
              identification: uploadResponse.resultado_members.members[i].user.identification,
              birthday: uploadResponse.resultado_members.members[i].user.birthday,
              sex: uploadResponse.resultado_members.members[i].user.sex,
              name: uploadResponse.resultado_members.members[i].user.name,
              lastname: uploadResponse.resultado_members.members[i].user.lastname,
              email: uploadResponse.resultado_members.members[i].user.email,
              phone: uploadResponse.resultado_members.members[i].user.phone,
              accountValidationEmailToken: uploadResponse.resultado_members.members[i].user.accountValidationEmailToken,
              accountValidationPhoneToken: uploadResponse.resultado_members.members[i].user.accountValidationPhoneToken,
              accountValidationInitialPasswordToken: uploadResponse.resultado_members.members[i].user.accountValidationInitialPasswordToken,
              active: uploadResponse.resultado_members.members[i].active,
              network: uploadResponse.resultado_members.members[i].network._id,
              branch: id,
            })
          }

        }

      }
    }

  }
  // Fin - Member

  const generateReportUsers = async () => {

  
    const jsonFromTsObjectArray = members.map(value=>({
      'TIPO IDENTIFICACION': value.typeIdentification,
      'IDENTIFICACION': value.identification,
      'FECHA NACIMIENTO': value.birthday,
      'SEXO': value.sex,
      'NOMBRES': value.name,
      'APELLIDOS': value.lastname,
      'CORREO ELECTRONICO': value.email,
      'TELEFONO': value.phone,
      'CUENTA VALIDADA': value.accountValidationInitialPasswordToken== undefined? 'VALIDADA': 'NO VALIDADA' ,
      'FECHA VALIDACION': value.accountValidationInitialPasswordToken== undefined? moment(value.accountValidationInitialPasswordDate).format("YYYY-MM-DD HH:mm:ss"): '',
      'ACTIVO': value.active ? 'SI': 'NO' ,
      'BLOQUEO LICENCIA': value.disabledByLicense ? 'SI': 'NO' ,
      'BLOQUEO GENERAL': value.blocked ? 'SI': 'NO' ,
    }))

    const worksheet = XLSX.utils.json_to_sheet(jsonFromTsObjectArray)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Listado de Abonados Principales")
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
    FileSaver(blob, "users.xlsx")
  }


  return (
    <div
      className='modal fade'
      id='branchModal'
      tabIndex='-1'
      aria-labelledby='branchModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='branchModalLabel'>
              {edit ? 'Editar Sucursal' : 'Agregar Sucursal'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {errorUpdate ? <Message variant='danger'>{errorUpdate}</Message> : ''}

            {errorUpload ? <Message variant='danger'>{errorUpload}</Message> : ''}
            {isError ? <Message variant='danger'>{isError}</Message> : ''}

            <form onSubmit={handleSubmit(submitHandler)}>

              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiOutlineApartment /> Datos de la Sucursal</a>
                </li>
                <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                  <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineAppstoreAdd onClick={formCleanHandler} /> Abonados Principales </a>
                </li>
                <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                  <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiOutlineTeam /> Administradores de Sucursal</a>
                </li>

                {isSuperAdmin ? (
                  <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                    <a className="nav-link" id="icon-tab-3" data-bs-toggle="tab" href="#icon-tabpanel-3" role="tab" aria-controls="icon-tabpanel-3" aria-selected="false"><AiOutlineFileDone /> Contratos</a>
                  </li>) : ''}

                {isSuperAdmin ? (
                  <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                    <a className="nav-link" id="icon-tab-4" data-bs-toggle="tab" href="#icon-tabpanel-4" role="tab" aria-controls="icon-tabpanel-4" aria-selected="false" ><AiOutlineGlobal /> Mi Ubicación</a>
                  </li>) : ''}

              </ul>

              <div className="tab-content pt-3" id="tab-content">
                <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">

                  <div className='row'>
                    <div className='col-md-6'>
                      {inputText({
                        register,
                        errors,
                        label: 'Nombre:',
                        name: 'name',
                        placeholder: 'Nombre',
                      })}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='isPublic'><b>Visibilidad:</b></label>
                      {inputCheckBox({
                        register,
                        errors,
                        watch,
                        name: 'isPublic',
                        label: 'Es Público?',
                        isRequired: false,
                        placeholder: 'Es Público?',
                      })}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='active'><b>Estado</b></label>
                      {inputCheckBox({
                        register,
                        errors,
                        watch,
                        name: 'active',
                        label: 'Activo',
                        isRequired: false,
                        placeholder: 'Activo',
                      })}
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-2">
                      <button type='submit' className='btn btn-primary form-control' data-bs-dismiss='modal' disabled={isLoadingPost}>
                        <AiOutlineEdit /> &nbsp; {isLoadingPost ? (
                          <span className='spinner-border spinner-border-sm' />
                        ) : (
                          edit ? 'Actualizar' : 'Crear '
                        )}
                      </button>
                    </div>
                    <div className="col-md-5">

                    </div>
                  </div>

                </div>
                <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">

                  <div>
                    <center>
                      <a className="btn btn-primary" href="#open-modal-member" onClick={() => cleanMember()} data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Agregar un Abonado Principal"> <AiOutlineUserAdd /> &nbsp;Agregar un Abonado Principal</a>
                      &nbsp;
                      <label htmlFor="uploadFile" className="btn btn-primary btn-block btn-outlined" data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Subir Abonados Principales">
                        {isLoadingUpload ? (
                          <span className='spinner-border spinner-border-sm' />
                        ) : (
                          ''
                        )}
                        <AiOutlineUpload /> &nbsp; Subir Abonado Principal
                      </label>
                      <input id="uploadFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} onClick={(e) => { e.target.value = null }} className='form-control' style={{ display: 'none' }} />
                      &nbsp;
                      <a href="#open-modal-upload-members" className='btn btn-primary btn-sm rounded-pill' data-bs-toggle="tooltip" data-bs-placement="top" title="Clic Pasos para Subir Abonados Principales" >
                        <FaQuestion />
                      </a>
                      &nbsp;
                      <a className="btn btn-primary" onClick={() => generateReportUsers()} data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Descargar Listado Abonados Principales"> <AiOutlineFileExcel /> &nbsp;Listado Abonados Principales</a>
                    </center>
                    <div id="open-modal-upload-members" className="modal-window-internal">
                      <div>
                        <b><h5 >Manual para subir múltiples abonados principales por Sucursal </h5></b>
                        <a href="#" title="Close" className="modal-close">&#x2715;</a>
                        <hr></hr>
                        <ol type="1">
                          <li>Descargar la siguiente plantilla de Excel para la la subida de abonados principales <a href={process.env.PUBLIC_URL + "/PlantillaMiembros.xlsx"} download={"Plantilla abonados.xlsx"} data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para descargar plantilla para subir abonados principales" >Plantilla</a></li>
                          <li>Llenar las columnas con la información. Las columnas con color verde es información requerida</li>
                          <li>Dar clic en el botón <b>Subir Abonados Principales</b> y seleccionar el archivo. Esperamos a que se realice el respectivo proceso</li>
                          <li>Se descarga un archivo con la información del procesamiento de cada uno de los abonados principales agregados o cuales tengan problemas</li>
                        </ol>


                      </div>
                    </div>
                    <br></br>
                  </div>

                  <DatatableWrapper body={members} headers={headers} paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 15,
                      options: [5, 10, 15, 20]
                    }
                  }} >
                    <Row className="mb-4">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter placeholder={'Ingrese información del abonado a encontrar'} />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions labels={{ beforeSelect: `Filas por página / Abonados encontrados ${members.length}` }} />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Table>
                      <TableHeader />
                      <TableBody>
                        {(rows) =>
                          rows.length === 0 ? (
                            <EmptyTablePlaceholder noResultsLabel={'Usuarios no encontrados'} />
                          ) : (
                            rows.map((rowData, rowIdx) => (
                              <tr key={rowIdx} className='text-center'>
                                <td>{rowData.name} {rowData.lastname}</td>
                                <td>
                                  {rowData.active ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}

                                </td>
                                <td>{rowData.identification}</td>
                                <td>
                                  {rowData.accountValidationPhoneToken =='' ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )} &nbsp;&nbsp;{rowData.phone}
                                </td>
                                <td>
                                  {rowData.accountValidationEmailToken =='' ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}&nbsp;&nbsp;{rowData.email}
                                </td>
                                <td>
                                  {rowData.accountValidationInitialPasswordToken == undefined ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}
                                </td>
                                <td>
                                  {rowData.blocked ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}
                                </td>

                                <td>
                                  <div className='btn-group'>

                                    <a className="btn btn-primary btn-sm rounded-pill" href="#open-modal-member" onClick={() => editMemberFunction(rowData._id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Abonado Principal"> <FaPenAlt /></a>
                                    &nbsp;
                                    <button className='btn btn-danger btn-sm ms-1 rounded-pill' onClick={() => { if (window.confirm('Desea eliminar el abonado principal de la Sucursal ?')) deleteMemberFunction(rowData._id) }} data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Abonado Principal">
                                      <span><FaTrash /></span>
                                    </button>
                                    &nbsp;


                                    <a className='btn btn-success btn-sm ms-1 rounded-pill' href={`#open-modal-network${rowData._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Redes del Abonado Principal" ><FaEye /></a>
                                    <div id={`open-modal-network${rowData._id}`} className="modal-window-internal">
                                      <div style={{ marginTop: "250px" }}  >
                                        <b><h5 >
                                          Listado de Redes del Abonado Principal
                                        </h5></b>
                                        <a href="#" title="Close" className="modal-close">&#x2715;</a>
                                        <hr></hr>
                                        <iframe id='iframeNetworks' src={`/admin/auth/networks?network_id=${rowData.network}&branch_id=${rowData.branch}` + ' '} width="100%" height="1000px" >
                                          <script type="text/javascript">
                                            document.getElementById(`iframeNetworks`).onload = function() {

                                              document.getElementById('iframeNetworks') != null ?
                                                document.getElementById('iframeNetworks').contentWindow.location.reload(true) : ''

                                            }
                                          </script>
                                        </iframe>
                                      </div>
                                    </div>

                                  </div>
                                </td>
                              </tr>

                            )
                            )
                          )
                        }
                      </TableBody>
                    </Table>
                  </DatatableWrapper>

                  <div id="open-modal-member" className="modal-window-internal">
                    <div>
                      <b><h5 >
                        {editMember ? 'Editar Abonado Principal' : 'Agregar Abonado Principal'}
                      </h5></b>
                      <a href="#" id="close-branch-member" title="Close" className="modal-close" onClick={() => cleanMember()}>&#x2715;</a>
                      <hr></hr>
                      <div className='row'>
                        <div className="col-md-12">

                          {editMember == false ? (

                            <div className='row'>
                              <div className="row">
                                <div className="col-md-2">
                                  <label><b>Búsqueda:</b></label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type='text'
                                    name='inputSearchMember'
                                    placeholder='Usuarios por identificación, email o teléfono'
                                    value={inputSearchMember}
                                    style={({ width: '100%' })}
                                    onChange={(event) => setInputSearchMember(event.target.value)}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                    <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                      onClick={() => formValidationSearchMember()}>
                                      <FaSearch />
                                    </button>
                                  </span>
                                  &nbsp;
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                    <button className='btn btn-primary btn-sm rounded-pill'
                                      onClick=
                                      {() => cleanMember()}>
                                      <FaBroom />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              &nbsp;
                              <div className='row text-center'>
                                {errorsListMember ? (<span className='text-danger'>{errorsListMember}</span>) : ''}
                              </div>
                              <br></br>
                              <hr></hr>
                            </div>

                          ) : ''}

                        </div>
                      </div>

                      <div className='row'>
                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationMember"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationMember(event.target.value)} disabled={isDisabledMember}
                              name='typeIdentificationMember' className='form-control' value={typeIdentificationMember}>
                              <option key="" >Seleccione una opción</option>
                              <option key="RUC" >RUC</option>
                              <option key="Cedula" >Cedula</option>
                              <option key="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Tipo Identificación:</b></label><br></br>
                            <label>{typeIdentificationMember == '' ? 'No ha ingresado' : typeIdentificationMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="identificationMember"><b>Identificación:</b></label>
                            <input
                              type='text'
                              name='identificationMember'
                              value={identificationMember}
                              onChange={(event) => setIdentificationMember(event.target.value)}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Identificación:</b></label><br></br>
                            <label>{identificationMember == '' ? 'No ha ingresado' : identificationMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="nameMember"><b>Nombres:</b></label>
                            <input
                              type='text'
                              name='nameMember'
                              value={nameMember}
                              onChange={(event) => setNameMember(event.target.value)}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Nombres:</b></label><br></br>
                            <label>{nameMember == '' ? 'No ha ingresado' : nameMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="lastNameMember"><b>Apellidos:</b></label>
                            <input
                              type='text'
                              name='lastNameMember'
                              value={lastNameMember}
                              onChange={(event) => setLastNameMember(event.target.value)}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Apellidos:</b></label><br></br>
                            <label>{lastNameMember == '' ? 'No ha ingresado' : lastNameMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="emailMember"><b>Correo Electrónico:</b></label>
                            <input
                              type='email'
                              name='emailMember'
                              value={emailMember}
                              onChange={(event) => setEmailMember(event.target.value)}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Correo Electrónico:</b></label><br></br>
                            <label>{emailMember == '' ? 'No ha ingresado' : emailMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="phoneNumberMember"><b>Teléfono:</b></label>
                            <PhoneInput
                              name="phoneNumberMember"
                              placeholder="Ingrese un número telefónico"
                              value={phoneNumberMember}
                              onChange={setPhoneNumberMember}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Teléfono:</b></label><br></br>
                            <label>{phoneNumberMember == '' ? 'No ha ingresado' : phoneNumberMember}</label>
                            <br></br>
                          </div>)}

                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="sexMember"><b>Sexo:</b></label>
                            <select onChange={(event) => setSexMember(event.target.value)} disabled={isDisabledMember}
                              name='sexMember' className='form-control' value={sexMember}>
                              <option key="" >Seleccione una opción</option>
                              <option key="Masculino" >Masculino</option>
                              <option key="Femenino" >Femenino</option>
                            </select>
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Sexo:</b></label><br></br>
                            <label>{sexMember == '' ? 'No ha ingresado' : sexMember}</label>
                            <br></br>
                          </div>)}


                        {editMember == false ? (
                          <div className="col-md-6">
                            <label htmlFor="birthdayMember"><b>F. Nacimiento:</b></label>
                            <input
                              type='date'
                              name='birthdayMember'
                              value={birthdayMember}
                              onChange={(event) => setBirthdayMember(event.target.value)}
                              className='form-control'
                              disabled={isDisabledMember}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>F. Nacimiento:</b></label><br></br>
                            <label>{birthdayMember == '' ? 'No ha ingresado' : birthdayMember}</label>
                            <br></br>
                          </div>)}
                      </div>

                      {editMember ? (<hr></hr>) : ''}

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="isActiveMember"><b>Estado:</b></label>
                          <div className='form-check form-switch form-control'>
                            <input className='form-check-input'
                              type='checkbox'
                              id='isActiveMember'
                              name='isActiveMember'
                              value={isActiveMember}
                              onChange={(event) => setIsActiveMember(event.target.checked)}
                              disabled={isDisabledMemberEdit}
                            />
                            <label className='form-check-label' htmlFor='isActiveMember'>
                              Activo
                            </label>
                          </div>
                        </div>

                        <div className='row'>&nbsp;</div>
                        <div className='row'>
                          <div className="col-md-4">
                            <p style={({ display: 'none' })}>{idMember}</p>
                          </div>
                          <div className="col-md-4">
                            <button type='button' onClick={() => addMember()} className='btn btn-primary form-control' disabled={isDisabledMemberEdit} data-bs-toggle="tooltip" data-bs-placement="top" title={isDisabledUserEdit == false ? ' Actualizar Abonado Principal ' : ' Crear Abonado Principal'}>
                              <AiOutlineUserAdd /> &nbsp;

                              {isLoadingBranch ? (
                                <span className='spinner-border spinner-border-sm' />
                              ) : ('')}

                              {editMember ? 'Actualizar' : 'Crear '}
                            </button>
                          </div>
                          <div className="col-md-4">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2" >

                  <center><a className="btn btn-primary" href="#open-modal-user" onClick={() => cleanUser()} data-bs-toggle="tooltip" data-bs-placement="top" title="Agregar un usuario con roles Administrativos en la sucursal" > <AiOutlineUserAdd /> &nbsp;Agregar un Usuario</a></center>
                  <br></br>

                  <DatatableWrapper body={users} headers={headersUsers} paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 15,
                      options: [5, 10, 15, 20]
                    }
                  }} >
                    <Row className="mb-4">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter placeholder={'Ingrese información del usuario a encontrar'} />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions labels={{ beforeSelect: `Filas por página / Usuarios encontrados ${users.length}` }} />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
                      </Col>
                    </Row>
                    <hr></hr>
                    <Table>
                      <TableHeader />
                      <TableBody>
                        {(rows) =>
                          rows.length === 0 ? (
                            <EmptyTablePlaceholder noResultsLabel={'Usuarios no encontrados'} />
                          ) : (
                            rows.map((rowData, rowIdx) => (
                              <tr key={rowIdx} className='text-center'>
                                <td>{rowData.name} {rowData.lastname}</td>
                                <td>
                                  {rowData.active ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}

                                </td>
                                <td>{rowData.role}</td>
                                <td>{rowData.identification}</td>
                                <td>
                                  {rowData.accountValidationPhoneToken =='' ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )} &nbsp;&nbsp;{rowData.phone}
                                </td>
                                <td>
                                  {rowData.accountValidationEmailToken =='' ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}&nbsp;&nbsp;{rowData.email}
                                </td>
                                <td>
                                  {rowData.accountValidationInitialPasswordToken == undefined ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}
                                </td>
                                <td>
                                  {rowData.blocked ? (
                                    <FaCheckCircle className='text-success' />
                                  ) : (
                                    <FaTimesCircle className='text-danger' />
                                  )}
                                </td>
                                <td>
                                  <div className='btn-group'>

                                    <a className="btn btn-primary btn-sm rounded-pill" href="#open-modal-user" onClick={() => editUserFunction(rowData._id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Usuario"> <FaPenAlt /></a>
                                    &nbsp;
                                    <button className='btn btn-danger btn-sm ms-1 rounded-pill' data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Usuario con Rol Administrativo de Sucursal" onClick={() => { if (window.confirm('Desea eliminar el usuario de admistradores de la Sucursal ?')) deleteUserFunction(rowData._id) }}>
                                      <span><FaTrash /></span>
                                    </button>

                                  </div>
                                </td>
                              </tr>

                            )
                            )
                          )
                        }
                      </TableBody>
                    </Table>
                  </DatatableWrapper>


                  <div id="open-modal-user" className="modal-window-internal">
                    <div>
                      <b><h5 >
                        {editUser ? 'Editar Usuario' : 'Agregar Usuario'}
                      </h5></b>
                      <a href="#" id="close-user-branch" title="Close" className="modal-close">&#x2715;</a>
                      <hr></hr>
                      <div className='row'>
                        <div className="col-md-12">

                          {editUser == false ? (
                            <div className='row'>
                              <div className="row">
                                <div className="col-md-2">
                                  <label><b>Búsqueda:</b></label>
                                </div>
                                <div className="col-md-8">
                                  <input
                                    type='text'
                                    name='inputSearchUser'
                                    placeholder='Usuarios por identificación, email o teléfono'
                                    value={inputSearchUser}
                                    style={({ width: '100%' })}
                                    onChange={(event) => setInputSearchUser(event.target.value)}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                    <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                      onClick={() => formValidationSearchUser()}>
                                      <FaSearch />
                                    </button>
                                  </span>
                                  &nbsp;
                                  <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                    <button className='btn btn-primary btn-sm rounded-pill'
                                      onClick=
                                      {() => cleanUser()}>
                                      <FaBroom />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              &nbsp;
                              <div className='row text-center'>
                                {errorsListUser ? (<span className='text-danger'>{errorsListUser}</span>) : ''}
                              </div>
                              <br></br>
                              <hr></hr>
                            </div>

                          ) : ''}

                        </div>
                      </div>

                      <div className='row'>

                        {editUser ? (
                          <div className="col-md-12">

                            <div className="alert alert-primary text-center" role="alert">
                              Editar un usuario solo se puede realizar desde el perfil de cada usuario
                            </div>
                          </div>) : ''}
                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="typeIdentificationUser"><b>Tipo Identificación:</b></label>
                            <select onChange={(event) => setTypeIdentificationUser(event.target.value)} disabled={isDisabledUser}
                              name='typeIdentificationUser' className='form-control' value={typeIdentificationUser}>
                              <option value="" >Seleccione una opción</option>
                              <option value="RUC" >RUC</option>
                              <option value="Cedula" >Cedula</option>
                              <option value="Pasaporte" >Pasaporte</option>
                            </select>
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Tipo Identificación:</b></label><br></br>
                            <label>{typeIdentificationUser == '' ? 'No ha ingresado' : typeIdentificationUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="identificationUser"><b>Identificación:</b></label>
                            <input
                              type='text'
                              name='identificationUser'
                              value={identificationUser}
                              onChange={(event) => setIdentificationUser(event.target.value)}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Identificación:</b></label><br></br>
                            <label>{identificationUser == '' ? 'No ha ingresado' : identificationUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="nameUser"><b>Nombres:</b></label>
                            <input
                              type='text'
                              name='nameUser'
                              value={nameUser}
                              onChange={(event) => setNameUser(event.target.value)}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Nombres:</b></label><br></br>
                            <label>{nameUser == '' ? 'No ha ingresado' : nameUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="lastNameUser"><b>Apellidos:</b></label>
                            <input
                              type='text'
                              name='lastNameUser'
                              value={lastNameUser}
                              onChange={(event) => setLastNameUser(event.target.value)}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Apellidos:</b></label><br></br>
                            <label>{lastNameUser == '' ? 'No ha ingresado' : lastNameUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="emailUser"><b>Correo Electrónico:</b></label>
                            <input
                              type='email'
                              name='emailUser'
                              value={emailUser}
                              onChange={(event) => setEmailUser(event.target.value)}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Correo Electrónico:</b></label><br></br>
                            <label>{emailUser == '' ? 'No ha ingresado' : emailUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="phoneNumberUser"><b>Teléfono:</b></label>
                            <PhoneInput
                              name="phoneNumberUser"
                              placeholder="Ingrese un número telefónico"
                              value={phoneNumberUser}
                              onChange={setPhoneNumberUser}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Teléfono:</b></label><br></br>
                            <label>{phoneNumberUser == '' ? 'No ha ingresado' : phoneNumberUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="sexUser"><b>Sexo:</b></label>
                            <select onChange={(event) => setSexUser(event.target.value)} disabled={isDisabledUser}
                              name='sexUser' className='form-control' value={sexUser}>
                              <option value="" >Seleccione una opción</option>
                              <option value="Masculino" >Masculino</option>
                              <option value="Femenino" >Femenino</option>
                            </select>
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>Sexo:</b></label><br></br>
                            <label>{sexUser == '' ? 'No ha ingresado' : sexUser}</label>
                            <br></br>
                          </div>)}

                        {editUser == false ? (
                          <div className="col-md-6">
                            <label htmlFor="birthdayUser"><b>F. Nacimiento:</b></label>
                            <input
                              type='date'
                              name='birthdayUser'
                              value={birthdayUser}
                              onChange={(event) => setBirthdayUser(event.target.value)}
                              className='form-control'
                              disabled={isDisabledUser}
                            />
                          </div>) : (
                          <div className="col-md-6">
                            <label><b>F. Nacimiento:</b></label><br></br>
                            <label>{birthdayUser == '' ? 'No ha ingresado' : birthdayUser}</label>
                            <br></br>
                          </div>)}
                      </div>
                      {editUser ? (<hr></hr>) : ''}

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="roleUser"><b>Rol:</b></label>
                          <select onChange={(event) => setRoleUser(event.target.value)} disabled={isDisabledUserEdit}
                            name='roleUser' className='form-control' value={roleUser}>
                            <option value="" >Seleccione una opción</option>
                            <option value="ADMIN_BRANCH" >Admin</option>
                            <option value="MONITOR_BRANCH" >Monitor</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="isActiveUser"><b>Estado:</b></label>
                          <div className='form-check form-switch form-control'>
                            <input className='form-check-input'
                              type='checkbox'
                              id='isActiveUser'
                              name='isActiveUser'
                              value={isActiveUser}
                              onChange={(event) => setIsActiveUser(event.target.checked)}
                              disabled={isDisabledUserEdit}
                            />
                            <label className='form-check-label' htmlFor='isActiveUser'>
                              Activo
                            </label>
                          </div>
                        </div>
                        <div className='row'>&nbsp;</div>
                        <div className='row col-md-12'>
                          <div className="col-md-4">
                            <p style={({ display: 'none' })}>{idUser}</p>
                          </div>
                          <div className="col-md-4">
                            <button type='button' onClick={addUser} className='btn btn-primary form-control' disabled={isDisabledUserEdit} data-bs-toggle="tooltip" data-bs-placement="top" title="Actualizar Organización" >
                              <AiOutlineUserAdd /> &nbsp;

                              {isLoadingBranch ? (
                                <span className='spinner-border spinner-border-sm' />
                              ) : ('')}

                              {isDisabledUserEdit == false ? ' Actualizar' : ' Crear '}

                            </button>
                          </div>
                          <div className="col-md-4">

                          </div>
                        </div>

                      </div>


                    </div>
                  </div>
                </div>

                {isSuperAdmin ? (
                  <div className="tab-pane" id="icon-tabpanel-3" role="tabpanel" aria-labelledby="icon-tab-3">
                    <iframe src={`/admin/auth/branch-licenses?branch_id=${id}`} width="100%" height="1200px"  ></iframe>
                  </div>) : ''}

                {isSuperAdmin ? (
                  <div className="tab-pane" id="icon-tabpanel-4" role="tabpanel" aria-labelledby="icon-tab-4">
                    <div className="row">

                      <iframe src={`/admin/auth/branches-maps?branch_id=${id}&latitude_branch=${latitudeBranch}&longitude_branch=${longitudeBranch}&radius_branch=${radiusBranch}`} width="100%" height="1200px"  ></iframe>

                    </div>
                  </div>) : ''}

              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export default FormBranches
